import { DateTime } from 'luxon';
import { ReportError } from '@/error_handling.js';
import { AutoQueue } from '@/utilities/auto_queue.js';
import { sleep, urlExpired } from '@/utilities/helpers';
import isEmpty from 'lodash/isEmpty';

const IMAGE_CACHE_VERSION = 1;
const CURRENT_CACHES = {
  images: `image-cache-${IMAGE_CACHE_VERSION}`,
};

const autoQueue = new AutoQueue();

// The DB Connection for indexedDB
let db = null;
// The isReady promise
let isReady = null;

async function setup() {
  if (isReady) {
    return isReady;
  }

  let isReadyPromise = null;
  isReady = new Promise((resolve, reject) => {
    isReadyPromise = { resolve, reject };
  });

  const idbOpen = indexedDB.open('CacheUsage', 1);
  idbOpen.onerror = (event) => {
    ReportError(event.target.error);
    isReadyPromise.reject(event);
  };
  idbOpen.onblocked = (e) => {
    console.log('Someone else is upgrading the DB!', e);
  };
  idbOpen.onupgradeneeded = (e) => {
    console.log('Upgrade DB!');
    const db = e.target.result;
    db.onerror = (event) => {
      ReportError(event.target.error);
      console.log('Error loading database.', event.target.error);
    };

    if (e.newVersion >= 1) {
      const store = db.createObjectStore('images', { keyPath: 'url' });
      store.createIndex('by_last_use', 'lastUsed');
    }
  };
  idbOpen.onsuccess = (e) => {
    console.log('Opened successfully!');
    db = e.target.result;
    db.onversionchange = () => {
      db.close();
      db = null;
      location.reload();
    };
    isReadyPromise.resolve();
  };
  console.log('Opening IndexedDB');

  return isReady;
}

export async function delayedClean() {
  await sleep(5000);
  await clean();
}

async function cleanCache(toDelete) {
  const cache = await caches.open(CURRENT_CACHES.images);
  for (const url of toDelete) {
    await cache.delete(url);
  }
}

export async function clean() {
  try {
    await setup();
  } catch (ex) {
    console.log('IndexedDB not available?');
    return;
  }
  console.log('Cleaning image cache');

  const expectedCacheNamesSet = new Set(Object.values(CURRENT_CACHES));
  const cacheNames = await caches.keys();

  await Promise.all(
    cacheNames.map((cacheName) => {
      if (!expectedCacheNamesSet.has(cacheName)) {
        // If this cache name isn't present in the set of
        // "expected" cache names, then delete it.
        console.log('Deleting out of date cache:', cacheName);
        return caches.delete(cacheName);
      }
    }),
  );

  // const keys = await cache.keys();
  const purgeCutoff = DateTime.now().minus({ days: 30 });
  const transaction = db.transaction('images', 'readwrite', { durability: 'relaxed' });
  const store = transaction.objectStore('images');
  const idx = store.index('by_last_use');
  const toDelete = [];
  idx.openCursor(IDBKeyRange.upperBound(purgeCutoff.toMillis())).onsuccess = (e) => {
    const cursor = e.target.result;
    if (cursor) {
      toDelete.push(cursor.primaryKey);
      cursor.delete();
      cursor.continue();
    } else {
      cleanCache(toDelete);
    }
  };
}

function updateLastUse(url) {
  try {
    const transaction = db.transaction('images', 'readwrite', { durability: 'relaxed' });
    const store = transaction.objectStore('images');
    const ret = store.get(url.toString());
    ret.onsuccess = (e) => {
      const current = e.target.result;
      const count = (current?.count || 0) + 1;
      store.put({ url: url.toString(), lastUsed: DateTime.now().toMillis(), count });
    };
  } catch (ex) {
    ReportError(ex, { tags: { handled: true } });
  }
}

export async function getImage(url) {
  try {
    await setup();
  } catch (ex) {
    console.log('IndexedDB not available?');
    return undefined;
  }

  const cacheUrl = new URL(url);
  cacheUrl.search = '';

  const cache = await caches.open(CURRENT_CACHES.images);

  return await cache.match(cacheUrl);
}

export async function fetchImageForUrlEntry(urlEntry, callback) {
  if (!isEmpty(urlEntry)) {
    const response = await getImage(urlEntry.url);
    if (response) {
      return response;
    }
  }

  if (urlExpired(urlEntry)) {
    urlEntry = await callback(urlEntry);
  }
  if (urlEntry) {
    return await fetchImage(urlEntry.url);
  } else {
    return undefined;
  }
}

export function fetchImage(url) {
  return autoQueue.enqueue(async () => realFetchImage(url));
}

async function realFetchImage(url) {
  try {
    await setup();
  } catch (ex) {
    console.log('IndexedDB not available?');
    const fullUrl = new URL(url);

    return await fetch(fullUrl, { credentials: 'omit', mode: 'cors' });
  }

  const fullUrl = new URL(url);
  const cacheUrl = new URL(url);
  cacheUrl.search = '';

  const cache = await caches.open(CURRENT_CACHES.images);

  const match = await cache.match(cacheUrl);
  if (match) {
    updateLastUse(cacheUrl);
    return match;
  } else {
    let response = await fetch(fullUrl, { credentials: 'omit', mode: 'cors' });

    if (response.ok) {
      try {
        await cache.put(cacheUrl, response.clone());
      } catch (e) {
        // error occurred putting?
        const other = await cache.match(cacheUrl);
        if (other) {
          response = other;
        }
      }
      updateLastUse(cacheUrl);
      return response;
    } else {
      return undefined;
    }
  }
}
